const downloadFromA = ({ url, filename, target }) => {
  const downloadElement = document.createElement('a');
  downloadElement.style.display = 'none';
  downloadElement.href = url;
  if (target) {
    downloadElement.target = '_blank';
  }
  downloadElement.rel = 'noopener noreferrer';
  if (filename) {
    downloadElement.download = filename;
  }
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
}

const expectDuration = (duration) => {
  return Math.ceil(duration * 2 / 60) + 'min'
  // return secondConvert(
  //   Math.ceil(naiveDuration(duration) / getConcurrency(duration))
  // );
};

export {
  downloadFromA,
  expectDuration
};