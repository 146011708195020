<template>
  <div class="remove-container">
    <div class="header flex">
      <back-btn @back="handleBack" />
    </div>
    <div class="video-container">
      <div class="video-box">
        <video 
          :src="displaySrc" 
          controls 
          controlslist="nodownload  noplaybackrate" 
          disablePictureInPicture 
          muted />
      </div>
    </div>
    <div class="footer">
      <custom-btn 
        activeColor="linear-gradient(289deg, #7646ff 18.5%, #9c7aff 99.95%)"
        :loading="downloadLoading"
        :customStyle="{ 
          width: '254px', 
          height: '62px', 
          fontSize: '16px',
          background: 'linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)', 'border-radius': '100px', 
          color: '#FFFFFF' }"
        @click="handleDownload">
        Remove BG
      </custom-btn>
    </div>
    <Modal :visible="showProessModal" :width="448" @cancel="handleCancel">
      <div class="modal-video-loading">
        <div class="modal-header">
          <img :src="success_icon" alt="success">
          <h1>Video processing </h1>
        </div>
        <p>It will take approximately {{ expectDuration(props.videoInfo?.duration) }} to process your full video. We
          will
          send you a notification when the process is completed.</p>
        <div class="modal-footer">
          <button class="modal-footer-button" @click.stop="handleCheckNotification">Check notification</button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script setup>
import { 
  ref,
  computed, 
  defineProps, 
  defineEmits, 
  onBeforeUnmount 
} from "vue";
import { 
  downloadFromA, 
  expectDuration 
} from './helper.js'
import { useMeta } from 'vue-meta'
import { authenticator } from '@/utils/index'
import { Modal } from '@/components/boolv-ui/index.js'

import { eventTracking } from "@/utils/eventTracking.js";
import { uploadVideoSingle } from "@/api/upload/upload.js";
import { getVideoBgRemove } from '@/api/models/video-background-remove.js';

import resultMp4 from './assets/video/result.mp4';
import demoMp4 from './assets/video/output360.mp4';
import success_icon from './assets/success_icon.svg';
import BackBtn from "@/components/boolv-ui/back-btn";
import CustomBtn from "@/components/boolv-ui/custom-btn/index.vue";

import {
  MessageSuccess,
  MessageError,
  MessageLoading,
  destroy
} from "@/components/boolv-ui/Message/index.js";
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/store/index.js'

useMeta({
  title: 'Remove and Replace the background in 5 seconds - Boolv.Toolkit',
  description: "Upload or drop an image - remove or replace the background – totally automatic – in 5 seconds – just one click – for free.",
  htmlAttrs: { lang: 'en', amp: true }
})

const props = defineProps({
  videoInfo: Object,
  mode: String,
  videoDuration: Number
})
const emits = defineEmits(['back'])

const user = useUserStore();
const { showNotification, updateNotification } = storeToRefs(user);

const showProessModal = ref(false);
const downloadFlag = ref(false);
const downloadLoading = ref(false);
const displaySrc = computed(() => props.mode === 'upload'? props.videoInfo?.url : demoMp4);

console.log(props.videoInfo);
const handleBack = () => {
  emits('back');
}

const downloadExampleVideo = () => {
  downloadLoading.value = false;
  eventTracking('booltool_page_download', { tool_name: 'video_enhancer', is_batch: false, is_success: true });
  
  downloadFromA({
    url:resultMp4,
    filename: 'video_bg_remove', 
    targer: true
  });

  MessageSuccess("Download completed");
  destroy();
  authenticator.refresh();
}

const handleDownload = async () => {
  downloadLoading.value = true;
  destroy();
  MessageLoading("Processing, it will take a while", 0, "", null, false);

  const { mode } = props;
  if( mode === 'example' ){
    downloadExampleVideo();
    return ;
  }
  if (downloadFlag.value) {
    destroy();
    // 点击过下载，再次点击下载，直接弹出消息提示框
    showNotification.value = true;
    downloadLoading.value = false;
    return ;
  }
  try {
    // await generateVideoTask({ previewId: downloadId.value })
    const { data } = await uploadVideoSingle({ video: props.videoInfo.file });
    await getVideoBgRemove({ videoS3Uri: data.url });
    downloadFlag.value = true;
    // 未处理完成，异步处理，弹框提示
    showProessModal.value = true;
    updateNotification.value++;
    downloadLoading.value = false;
  } catch (_) {
    MessageError("download error");
  }
  destroy();
  authenticator.refresh();
  downloadLoading.value = false;
}

const handleCancel = () => {
  showProessModal.value = false
}

const handleCheckNotification = () => {
  showProessModal.value = false
  showNotification.value = true;
}

onBeforeUnmount(() => {
  destroy();
});
</script>
<style lang="less" scoped>
.remove-container {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 60px);
  padding: 16px 36px 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  height: 42px;
  width: 100%;
  display: flex;
}

.footer {
  margin-top: 38px;
}

.video-container {
  height: 80%;
}

.video-box {
  height: 100%;

  & video {
    height: 100%;
  }
}


.modal-video-loading {
  padding: 24px;

  .modal-header {
    display: flex;

    h1 {
      margin-left: 16px;
      font-weight: 500;
      font-size: 16px;
    }
  }

  p {
    padding: 10px 24px 24px 40px;
  }

  .modal-footer {
    text-align: right;

    .modal-footer-button {
      background: linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%);
      border-radius: 153px;
      padding: 5px 10px;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
    }

    .modal-footer-button:hover {
      background: linear-gradient(289deg, #6f46f4 5.38%, #957aec 99.95%);
    }

  }
}
</style>
