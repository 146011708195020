<template>
  <!-- 人像抠图 -->
  <bg-remove 
    v-if="uploadComplete" 
    :mode="mode"
    :videoInfo="uploadVideoInfo" 
    :videoDuration="videoDuration" 
    @back="handleBack"  />
  <video-upload 
    v-else
    @uploadComplete="handleUploadComplete" 
    @videoDuration="getVideoDuration"/>
</template>
<script setup>
import VideoUpload from './video-upload.vue'
import bgRemove from './video-bg-remove.vue'
import { ref } from 'vue'
import { eventTracking } from "@/utils/eventTracking.js";
import { getMaterialResolution } from "@/utils/index.js"
import {
  MessageError,
  destroy,
} from "@/components/boolv-ui/Message/index.js";

const mode = ref("");
const uploadComplete = ref(false);
const uploadVideoInfo = ref([]);
const videoDuration = ref(0)
const exceedMaxPx = ref(false)

const getMaterialResolutionCallback = (width, height) => {
  if (width <= 2560 && height <= 1440 ) {
    exceedMaxPx.value = false;
  } else {
    destroy();
    MessageError('Max 1440p resolution');
    exceedMaxPx.value = true;
    return;
  }
}

const handleUploadComplete = async (m, info) => {
  mode.value = m;
  eventTracking(
    "booltool_page_upload", 
    { 
      tool_name: "video_bgRemove", 
      is_batch: false 
    }
  );
  if (m == 'upload') {
    const { width, height } = await getMaterialResolution(
      info.file, 
      getMaterialResolutionCallback
    );
    getMaterialResolutionCallback(width, height);
  }

  if (!exceedMaxPx.value) {
    uploadComplete.value = true;
    uploadVideoInfo.value = info;
    console.log(uploadVideoInfo.value, '[upload]');
  }
};

const getVideoDuration = (value) => {
  videoDuration.value = value;
}

const handleBack = () => {
  uploadVideoInfo.value = {};
  uploadComplete.value = false;
}
</script>

<style lang="less">

</style>